import React, { createContext, useReducer } from 'react';

import { getParam } from '../lib/get-param';

const NL = 'nl';
const FR = 'fr';

const languageReducer = (state, action) => {
    switch (action) {
        case NL:
            return NL;

        case FR:
            return FR;

        default:
            return NL;
    }
};

export const LanguageContext = createContext(null);
let languageUsed = NL;

const LanguageProvider = ({ children }) => {
    if (getParam('lang') === FR) {
        languageUsed = FR;
    }

    const tuple = useReducer(languageReducer, languageUsed);

    return (
        <LanguageContext.Provider value={tuple}>
            {children}
        </LanguageContext.Provider>
    );
};

export default LanguageProvider;
