import {
    ADD_REWARD,
    AppContext,
    CREATE_REWARD,
    DELETE_REWARD,
    SUBTRACT_REWARD,
} from '../contexts/App';
import React, { useContext, useState } from 'react';

import { Button } from './generic/Button';
import { IconText } from './generic/IconText';
import { LanguageContext } from '../contexts/Language';
import ProductCounter from './ProductCounter';
import { ProductDetail } from './ProductDetail';
import { Tag } from './generic/Tag';
import { cleanUrl } from '../lib/clean-url';
import { strings } from '../i18n/localization';

export const CREATE = 'create';
export const ADD = 'add';
export const SUBTRACT = 'subtract';
export const DELETE = 'delete';

export const Product = ({ product, count }) => {
    const initialCount = count ? count.count : 0;
    const [productCount, setProductCount] = useState(initialCount);
    const [modalOpen, setModalOpen] = useState(false);
    const [data, dispatch] = useContext(AppContext);
    const [activeLanguage] = useContext(LanguageContext);

    const handleModal = () => {
        setModalOpen(oldModal => !oldModal);
    };

    const handleSelect = (id, cost, type = CREATE) => {
        const payload = {
            cost: cost,
            id: id,
        };

        switch (type) {
            case CREATE:
                setProductCount(1);
                dispatch({
                    type: CREATE_REWARD,
                    payload: payload,
                });
                break;
            case ADD:
                setProductCount(oldState => ++oldState);
                dispatch({
                    type: ADD_REWARD,
                    payload: { ...payload, count: productCount + 1 },
                });
                break;

            case SUBTRACT:
                setProductCount(oldState => --oldState);
                dispatch({
                    type: SUBTRACT_REWARD,
                    payload: { ...payload, count: productCount - 1 },
                });
                break;

            case DELETE:
                setProductCount(0);
                dispatch({
                    type: DELETE_REWARD,
                    payload: payload,
                });
                break;
            default:
                break;
        }
    };

    let cardClasses = 'card flex flex-col h-full bg-white rounded-xl shadow-md';
    let circleClasses =
        'btn-circle btn rounded-full h-6 w-6 m-2 bg-transparent flex items-center justify-center absolute top-0 right-0 z-10 border-2 border-white';

    if (productCount) {
        cardClasses += ' selected';
        circleClasses += ' bg-success';
    }

    const isDisabled = data.points - product.cost < 0;

    const name = product[activeLanguage].name;
    const shortDescription = product[activeLanguage].short_description;
    const address = product[activeLanguage].address;
    const website = product[activeLanguage].website;

    return (
        <article>
            <div className={cardClasses}>
                <button
                    disabled={isDisabled}
                    onClick={() =>
                        handleSelect(
                            product.id,
                            product.cost,
                            productCount ? DELETE : CREATE
                        )
                    }
                    className={circleClasses}></button>
                <aside className="card-image">
                    <Tag position="top-left">
                        <IconText text="small" type="small" icon="coins">
                            <span className="text-sm">{product.cost}</span>
                        </IconText>
                    </Tag>
                    <img
                        className="w-full rounded-t-lg"
                        src={product.thumbnail_url}
                        alt={name}
                        title={name}
                        loading="lazy"
                    />
                    <div
                        className={`absolute bottom-0 w-full p-1 text-xs tracking-widest text-center text-white uppercase bg-dark-transparent ${
                            isDisabled ? 'opacity-100' : 'opacity-0'
                        }`}>
                        {strings.product.notEnoughPoints}
                    </div>
                </aside>
                <div className="flex flex-col justify-between flex-grow h-full px-4 py-4 md:px-6">
                    <section>
                        <header className="mb-3">
                            <h4 className="mt-1 text-lg text-dark">{name}</h4>
                            {product.meta && (
                                <p className="text-sm text-info">
                                    {product.meta}
                                </p>
                            )}
                        </header>
                        {shortDescription && (
                            <p className="mb-5">{shortDescription}</p>
                        )}
                        <div className="mb-5">
                            {address && (
                                <IconText icon="address">
                                    <address>{address}</address>
                                </IconText>
                            )}
                            {website && (
                                <IconText icon="website">
                                    <a
                                        className="no-underline text-dark"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href={website}>
                                        {cleanUrl(website)}
                                    </a>
                                </IconText>
                            )}
                            {product.people && (
                                <IconText icon="people">
                                    {product.people}{' '}
                                    {product.people === 1
                                        ? strings.product.person.toLowerCase()
                                        : strings.product.persons.toLowerCase()}
                                </IconText>
                            )}
                        </div>
                    </section>
                    <footer className="flex flex-col align-center">
                        <div className="flex justify-center mb-3">
                            {productCount > 0 ? (
                                <ProductCounter
                                    disabled={isDisabled}
                                    product={product}
                                    productCount={productCount}
                                    handleSelect={handleSelect}
                                />
                            ) : (
                                <Button
                                    disabled={isDisabled}
                                    onClick={() =>
                                        handleSelect(product.id, product.cost)
                                    }>
                                    {strings.product.select}
                                </Button>
                            )}
                        </div>
                        <Button
                            className="py-1 text-secondary"
                            onClick={handleModal}
                            type="transparent">
                            {strings.product.knowMore}
                        </Button>
                    </footer>
                </div>
            </div>
            {modalOpen && (
                <ProductDetail
                    activeLanguage={activeLanguage}
                    product={product}
                    handleSelect={handleSelect}
                    handleModal={handleModal}
                    isDisabled={isDisabled}
                    productCount={productCount}
                    modalOpen={modalOpen}
                />
            )}
        </article>
    );
};
