import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ErrorMessage } from './generic/ErrorMessage';
import { HtmlParser } from './generic/HtmlParser';
import { IconText } from './generic/IconText';
import { ReactComponent as Logo } from '../assets/logo.svg';
import React, { useContext } from 'react';
import { ReactComponent as Shape } from '../assets/shapes/header.svg';
import Spinner from './generic/Spinner';
import { strings } from '../i18n/localization';
import { useContent } from '../hooks/content';
import { Button } from './generic/Button';
import { LanguageContext } from '../contexts/Language';
import { setParam } from '../lib/get-param';

const languages = strings.getAvailableLanguages();

export const Header = React.memo(({ data, isLoading, points, children }) => {
    const [content, loadingContent, loadingContentError] = useContent();
    const [activeLanguage, setActiveLanguage] = useContext(LanguageContext);

    const changeLanguage = lang => {
        setActiveLanguage(lang);
        strings.setLanguage(lang);
        setParam('lang', lang);
    };

    const translatedContent = content && content[0][activeLanguage];

    return (
        <header className="mb-20 header">
            <div className="bg-primary">
                <section className="container">
                    <nav className="flex justify-between py-8">
                        <Logo className="h-auto icon-white w-mobile-logo sm:w-logo" />
                        <div className="flex">
                            {points && (
                                <IconText
                                    type="white"
                                    className="text-white"
                                    icon="coins">
                                    {points}
                                </IconText>
                            )}
                            {languages.length > 1 && (
                                <div className="flex pl-4 ml-4 border-l border-white">
                                    {languages.map((language, index) => (
                                        <Button
                                            className={`mx-1 w-5 leading-normal text-white ${
                                                language === activeLanguage
                                                    ? 'font-bold'
                                                    : 'font-normal'
                                            }`}
                                            key={index}
                                            type="inline"
                                            onClick={() =>
                                                changeLanguage(language)
                                            }>
                                            {language.toUpperCase()}
                                        </Button>
                                    ))}
                                </div>
                            )}
                        </div>
                    </nav>
                </section>
            </div>
            <Shape className="w-full h-10 icon-primary md:h-20 lg-h-40" />
            <div className="container relative -mt-10 md:-mt-20">
                {translatedContent ? (
                    <section className="p-4 px-6 bg-white rounded-lg shadow-xl intro-grid md:p-8">
                        <article>
                            {translatedContent.first_col_title && (
                                <h1 className="mt-0 mb-4">
                                    {translatedContent.first_col_title}
                                </h1>
                            )}
                            {translatedContent.first_col_content && (
                                <HtmlParser>
                                    {translatedContent.first_col_content}
                                </HtmlParser>
                            )}
                        </article>
                        <nav>
                            {translatedContent.second_col_title && (
                                <h2 className="mb-4 text-lg">
                                    {translatedContent.second_col_title}
                                </h2>
                            )}
                            {translatedContent.second_col_content && (
                                <HtmlParser>
                                    {translatedContent.second_col_content}
                                </HtmlParser>
                            )}
                            {!isLoading ? (
                                data.length >= 1 ? (
                                    data.map(category => (
                                        <AnchorLink
                                            className="block mb-2 text-dark"
                                            key={category.id}
                                            href={`#${category.id}`}
                                            offset={25}>
                                            {category.name}
                                        </AnchorLink>
                                    ))
                                ) : (
                                    <p
                                        className="mb-8 text-secondary"
                                        role="alert">
                                        {strings.general.noCategories}
                                    </p>
                                )
                            ) : (
                                <Spinner className="my-10" />
                            )}
                        </nav>
                        <article>
                            {translatedContent.third_col_title && (
                                <h2 className="mb-4 text-lg">
                                    {translatedContent.third_col_title}
                                </h2>
                            )}
                            {translatedContent.third_col_content && (
                                <HtmlParser>
                                    {translatedContent.third_col_content}
                                </HtmlParser>
                            )}
                        </article>
                    </section>
                ) : loadingContent && !loadingContentError ? (
                    <Spinner />
                ) : loadingContentError ? (
                    <ErrorMessage>{loadingContentError.message}</ErrorMessage>
                ) : (
                    <ErrorMessage>
                        {strings.errors.somethingWentWrong}
                    </ErrorMessage>
                )}
            </div>
            {children}
        </header>
    );
});
