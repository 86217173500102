import React from 'react';

export const Tag = ({ children, className, warning, position }) => {
    let tagClasses = 'inline-block px-3 rounded-full border-2 bg-white';

    if (warning) {
        tagClasses += '  border-warning';
    } else {
        tagClasses += ' border-white';
    }

    if (position === 'top-left') {
        tagClasses += ' absolute top-0 left-0 m-2 z-10';
    }

    if (className) {
        tagClasses += ` ${className}`;
    }

    return <div className={tagClasses}>{children}</div>;
};
