import { Button } from './generic/Button';
import { HtmlParser } from './generic/HtmlParser';
import { IconText } from './generic/IconText';
import Modal from './generic/Modal';
import React from 'react';
import { Tag } from './generic/Tag';
import { strings } from '../i18n/localization';
import ProductCounter from './ProductCounter';

export const ProductDetail = ({
    activeLanguage,
    product,
    handleSelect,
    handleModal,
    isDisabled,
    productCount,
    modalOpen,
}) => {
    const name = product[activeLanguage].name
        ? product[activeLanguage].name
        : '';
    const description = product[activeLanguage].description
        ? product[activeLanguage].description
        : '';

    return (
        <Modal handleModal={handleModal} isOpen={modalOpen}>
            <aside>
                <figure>
                    <img
                        className="object-cover w-full h-48 shadow-xl sm:h-64 rounded-xl"
                        src={product.image_url}
                        alt={name}
                        title={name}
                        loading="lazy"
                    />
                </figure>
            </aside>
            <section className="pt-8">
                <div className="mb-8">
                    <h1 className="mb-2 text-2xl">{name}</h1>
                    <Tag className="shadow">
                        <IconText text="small" type="small" icon="coins">
                            <span className="text-sm">{product.cost}</span>
                        </IconText>
                    </Tag>
                </div>
                <article>
                    <HtmlParser>{description}</HtmlParser>
                </article>
                <div className="flex justify-start max-w-xs mt-10 mb-3">
                    {productCount > 0 ? (
                        <ProductCounter
                            disabled={isDisabled}
                            product={product}
                            productCount={productCount}
                            handleSelect={handleSelect}
                        />
                    ) : (
                        <Button
                            disabled={isDisabled}
                            onClick={() =>
                                handleSelect(product.id, product.cost)
                            }>
                            {strings.product.select}
                        </Button>
                    )}
                </div>
            </section>
        </Modal>
    );
};
