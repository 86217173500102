import React from 'react';

export const ErrorMessage = ({ children, className }) => {
    let errorClasses =
        'p-4 text-white rounded-lg bg-warning shadow-lg relative';

    if (className) {
        errorClasses += ` ${className}`;
    }

    return <p className={errorClasses}>{children}</p>;
};
