import { getParam } from '../lib/get-param';
import useAxios from 'axios-hooks';

export const useUser = () => {
    const param = getParam('id');

    const [{ data, loading, error }, execute] = useAxios(
        param ? `/api/user/${param}` : null
    );

    return [data, loading, error, execute];
};
