export const fr = Object.freeze({
    /**************************************************
     * APP
     **************************************************/
    /**************************************************
     * GENERAL STRINGS
     **************************************************/
    general: {
        loading: 'Chargement',
        error: 'Quelque chose a mal tourné.',
        noProducts: "Il n'y a aucun produit pour cette catégorie.",
        noCategories: "Aucune catégorie de prix n'a été créée.",
        pt: 'pt.',
        cancel: 'Annuler',
    },
    /**************************************************
     * PRODUCT STRINGS
     **************************************************/
    product: {
        select: 'Sélectionnez le prix',
        remove: 'Supprimer la sélection',
        person: 'Personne',
        persons: 'Personnes',
        knowMore: 'En savoir plus',
        notEnoughPoints: "Vous n'avez pas assez de points",
        amount: 'Nombre',
    },
    /**************************************************
     * BOTTOM BAR
     **************************************************/
    bar: {
        confirm: 'Confirmer',
        confirmTitle: 'Confirmer la sélection',
        confirmDescription:
            "Êtes-vous sûr de vouloir choisir les prix sélectionnés?",
        availablePoint: 'Point disponible',
        availablePoints: 'Points disponibles',
        selectedReward: 'Prix sélectionné',
        selectedRewards: 'Prix sélectionnés',
    },
    /**************************************************
     * ERROR
     **************************************************/
    errors: {
        notAuthorized: "Vous n'êtes pas autorisé à visiter cette page.",
        cantRedeem:
            "Vous n'êtes pas autorisé à sélectionner les prix. Ceci est possible car vous avez déjà envoyé un choix.",
        availablePoints: 'Points disponibles',
        submitError:
            "Une erreur s'est produite lors de l'envoi de votre sélection",
        select: 'Veuillez sélectionner un produit',
        pageNotFound: 'Cette page est introuvable.',
        somethingWentWrong: 'Quelque chose a mal tourné.',
    },
    /**************************************************
     * SUCCESS
     **************************************************/
    success: {
        sent: 'Votre sélection a été envoyée avec succès.',
    },
});
