import { Category } from './Category';
import React from 'react';
import { strings } from '../i18n/localization';

export const Categories = React.memo(
    ({ data, initialRewards, activeLanguage }) => {
        return data.length >= 1 ? (
            data.map(category => (
                <Category
                    key={category.id}
                    id={category.id}
                    name={category[activeLanguage].name}
                    products={category.products}
                    initialRewards={initialRewards}
                />
            ))
        ) : (
            <p className="container mb-8 text-secondary" role="alert">
                {strings.general.noCategories}
            </p>
        );
    }
);
