import React from 'react';

export const SuccessMessage = ({ children, className }) => {
    let successClasses =
        'p-4 text-white rounded-lg bg-success shadow-lg relative';

    if (className) {
        successClasses += ` ${className}`;
    }

    return <p className={successClasses}>{children}</p>;
};
