export const nl = Object.freeze({
    /**************************************************
     * APP
     **************************************************/
    /**************************************************
     * GENERAL STRINGS
     **************************************************/
    general: {
        loading: 'Laden',
        error: 'Er is iets misgelopen.',
        noProducts: 'Er zijn geen producten voor deze categorie.',
        noCategories: 'Er zijn geen prijscategorieën aangemaakt.',
        pt: 'pt.',
        cancel: 'Annuleren',
    },
    /**************************************************
     * PRODUCT STRINGS
     **************************************************/
    product: {
        select: 'Prijs selecteren',
        remove: 'Selectie verwijderen',
        person: 'Persoon',
        persons: 'Personen',
        knowMore: 'Meer weten',
        notEnoughPoints: 'Je hebt niet genoeg punten',
        amount: 'Aantal',
    },
    /**************************************************
     * BOTTOM BAR
     **************************************************/
    bar: {
        confirm: 'Bevestigen',
        confirmTitle: 'Selectie bevestigen',
        confirmDescription:
            'Ben je zeker dat je de geselecteerde prijzen wilt kiezen?',
        availablePoint: 'Beschikbaar punt',
        availablePoints: 'Beschikbare punten',
        selectedReward: 'Prijs geselecteerd',
        selectedRewards: 'Prijzen geselecteerd',
    },
    /**************************************************
     * ERROR
     **************************************************/
    errors: {
        notAuthorized: 'Je bent niet geautoriseerd om deze pagina te bezoeken.',
        cantRedeem:
            'Je bent niet geautoriseerd om prijzen te selecteren. Dit is mogelijks omdat je reeds een keuze hebt doorgestuurd.',
        availablePoints: 'Beschikbare punten',
        submitError: 'Er ging iets mis bij het verzenden van jouw selectie',
        select: 'Gelieve een product te selecteren',
        pageNotFound: 'Deze pagina kon niet gevonden worden.',
        somethingWentWrong: 'Er is iets misgegaan.',
    },
    /**************************************************
     * SUCCESS
     **************************************************/
    success: {
        sent: 'Je inzending is succesvol verzonden.',
    },
});
