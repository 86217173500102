import React, { useState } from 'react';

import { ReactComponent as Chevron } from '../../assets/icons/chevron-down.svg';
import useCollapse from 'react-collapsed';

export const Collapse = ({ header, id, children, open = true }) => {
    const [isOpen, setOpen] = useState(open);
    const { getCollapseProps, getToggleProps } = useCollapse({
        isOpen,
    });

    let chevronClasses = 'icon icon-primary ml-2';

    if (isOpen) {
        chevronClasses += '  rotated';
    }

    return (
        <section id={id}>
            <button
                {...getToggleProps({
                    onClick: () => setOpen(oldOpen => !oldOpen),
                })}
                className="flex items-center justify-between w-full px-4 py-1 text-left rounded-lg collapse">
                <h3 className="m-0 text-dark">{header}</h3>
                <Chevron className={chevronClasses} />
            </button>
            <section {...getCollapseProps()}>{children}</section>
        </section>
    );
};
