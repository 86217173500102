import React from 'react';

export const Button = ({
    type = 'primary',
    className,
    color,
    disabled,
    ariaLabel,
    children,
    onClick,
}) => {
    let buttonClasses = 'btn rounded-full';

    switch (type) {
        case 'primary':
            buttonClasses += ' btn-primary text-white   px-4 sm:px-8 py-3';
            break;
        case 'secondary':
            buttonClasses += ' btn-secondary text-white   px-4 sm:px-8 py-3';
            break;
        case 'transparent':
            buttonClasses += ' btn-transparent px-4 sm:px-8 py-2';
            break;
        case 'inline':
            buttonClasses += ' btn-transparent';
            break;
        case 'circle':
            buttonClasses +=
                ' btn rounded-full w-10 h-10 flex justify-center items-center text-white';
            break;
        default:
            buttonClasses += ' btn-primary px-4 sm:px-8';
            break;
    }

    if (className) {
        buttonClasses += ` ${className}`;
    }

    if (color) {
        buttonClasses += ` bg-${color}`;
    }

    return (
        <button
            disabled={disabled}
            aria-label={ariaLabel}
            onClick={onClick}
            className={buttonClasses}>
            {children}
        </button>
    );
};
