import { ReactComponent as Address } from '../../assets/icons/pin.svg';
import { ReactComponent as Check } from '../../assets/icons/check.svg';
import { ReactComponent as Coins } from '../../assets/icons/coins.svg';
import { ReactComponent as List } from '../../assets/icons/list.svg';
import { ReactComponent as People } from '../../assets/icons/users.svg';
import React from 'react';
import { ReactComponent as Website } from '../../assets/icons/globe.svg';

const types = {
    address: Address,
    people: People,
    website: Website,
    list: List,
    coins: Coins,
    check: Check,
};

export const IconText = ({ icon, type, children, className }) => {
    let Icon = types[icon];
    let containerClasses = 'flex items-center my-1 dont-break-out';
    let iconClasses = 'mr-3 icon';

    if (className) {
        containerClasses += ` ${className}`;
    }

    switch (type) {
        case 'small-mobile':
            iconClasses += ' icon-small-sm';
            break;
        case 'small':
            iconClasses += ' icon-sm';
            break;
        case 'white':
            iconClasses += ' icon-white';
            break;
        default:
            break;
    }

    return (
        <div className={containerClasses}>
            <Icon className={iconClasses} />
            <div>{children}</div>
        </div>
    );
};
