export const getParam = key => {
    return new URLSearchParams(window.location.search).get(key);
};

export const setParam = (key, value) => {
    const params = new URLSearchParams(window.location.search);
    params.set(key, value);
    window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${params}`
    );
};
