import React, { createContext, useReducer } from 'react';

export const CREATE_REWARD = 'create_reward';
export const ADD_REWARD = 'add_reward';
export const SUBTRACT_REWARD = 'subtract_reward';
export const DELETE_REWARD = 'delete_reward';
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';

const DEFAULT_STATE = {
    points: 0,
    rewards: [],
};

const AppReducer = (state, action) => {
    switch (action.type) {
        case CREATE_REWARD:
            return {
                points: state.points - action.payload.cost,
                rewards: [
                    ...state.rewards,
                    {
                        id: action.payload.id,
                        count: 1,
                    },
                ],
            };

        case ADD_REWARD:
            return {
                points: state.points - action.payload.cost,
                rewards: state.rewards.map(reward =>
                    reward.id === action.payload.id
                        ? {
                              ...reward,
                              count: action.payload.count,
                          }
                        : reward
                ),
            };

        case SUBTRACT_REWARD:
            return {
                points: state.points + action.payload.cost,
                rewards: state.rewards.map(reward =>
                    reward.id === action.payload.id
                        ? {
                              ...reward,
                              count: action.payload.count,
                          }
                        : reward
                ),
            };

        case DELETE_REWARD:
            const existingIndex = state.rewards.findIndex(
                reward => reward.id === action.payload.id
            );

            return {
                points:
                    state.points +
                    action.payload.cost * state.rewards[existingIndex].count,
                rewards: state.rewards.filter(
                    reward => reward.id !== action.payload.id
                ),
            };

        case SET_INITIAL_STATE:
            const initialPoints = action.payload.redeemed
                ? action.payload.points - action.payload.redeemed.total
                : action.payload.points;

            const initialRedeemed = action.payload.redeemed
                ? action.payload.redeemed.products.map(product => ({
                      id: product.id,
                      count: product.count,
                  }))
                : [];

            return {
                ...state,
                rewards: initialRedeemed,
                points: initialPoints,
            };

        default:
            return DEFAULT_STATE;
    }
};

export const AppContext = createContext(null);

const AppProvider = ({ children }) => {
    const tuple = useReducer(AppReducer, DEFAULT_STATE);

    return <AppContext.Provider value={tuple}>{children}</AppContext.Provider>;
};

export default AppProvider;
