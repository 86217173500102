import { Collapse } from './generic/Collapse';
import { Products } from './Products';
import React from 'react';

export const Category = ({ name, id, products, initialRewards }) => {
    return (
        <Collapse header={name} id={id}>
            <Products products={products} initialRewards={initialRewards} />
        </Collapse>
    );
};
