import { AppContext, SET_INITIAL_STATE } from '../contexts/App';
import React, { useContext, useEffect } from 'react';
import { getParam } from '../lib/get-param';

import { ErrorScene } from './ErrorScene';
import { Rewards } from './Rewards';
import Spinner from './generic/Spinner';
import { strings } from '../i18n/localization';
import { useUser } from '../hooks/user';

const Shell = () => {
    const [user, , userError] = useUser();
    const dispatch = useContext(AppContext)[1];

    useEffect(() => {
        if (user && user.can_redeem) {
            dispatch({
                type: SET_INITIAL_STATE,
                payload: {
                    points: user.points,
                    redeemed: JSON.parse(user.redeemed),
                },
            });
        }
    }, [dispatch, user]);

    if (user) {
        if (user.can_redeem) {
            return <Rewards user={user} />;
        } else {
            return <ErrorScene>{strings.errors.cantRedeem}</ErrorScene>;
        }
    } else if (!userError) {
        return <Spinner className="mt-32" />;
    } else {
        if(getParam('preview')){
            return <Rewards />;
        }

        return <ErrorScene>{strings.errors.notAuthorized}</ErrorScene>;
    }
};

export default Shell;
