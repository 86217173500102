import LocalizedStrings from 'react-localization';
import { fr } from './fr';
import { getParam } from '../lib/get-param';
import { nl } from './nl';

let defaultLanguages = { nl, fr };

if (getParam('lang') === 'fr') {
    defaultLanguages = { fr, nl };
}

export const strings = new LocalizedStrings(defaultLanguages);
