import { ReactComponent as Logo } from '../assets/logo.svg';
import React from 'react';
import { ReactComponent as Shape } from '../assets/shapes/footer.svg';

export const Footer = React.memo(() => {
    return (
        <footer>
            <Shape className="w-full h-auto -mb-1 icon-primary" />
            <div className="pt-10 pb-20 md:pt-5 lg:pt-0 bg-primary">
                <div
                    className="container flex justify-between"
                    role="contentinfo">
                    <Logo className="h-auto icon-white w-logo" />
                </div>
            </div>
        </footer>
    );
});
