import React, { useContext } from 'react';

import { AppContext } from '../contexts/App';
import { BottomBar } from './BottomBar';
import { Categories } from './Categories';
import { ErrorMessage } from './generic/ErrorMessage';
import { Footer } from './Footer';
import { Header } from './Header';
import { LanguageContext } from '../contexts/Language';
import Spinner from './generic/Spinner';
import { ToastContainer } from 'react-toastify';
import { strings } from '../i18n/localization';
import { useData } from '../hooks/data';

export const Rewards = ({ user }) => {
    const [apiData, loadingData, loadingDataError] = useData();
    const [appData] = useContext(AppContext);
    const [activeLanguage] = useContext(LanguageContext);

    return (
        <>
            <Header
                points={appData.points}
                data={apiData}
                isLoading={loadingData}
            />
            <section className="container flex-grow">
                {!loadingDataError ? (
                    !loadingData ? (
                        <Categories
                            data={apiData}
                            activeLanguage={activeLanguage}
                            initialRewards={appData.rewards}
                        />
                    ) : (
                        <Spinner className="mt-32" />
                    )
                ) : (
                    <ErrorMessage activeLanguage={activeLanguage}>
                        {strings.general.error}
                    </ErrorMessage>
                )}
            </section>
            <Footer />
            <BottomBar
                user={user}
                data={appData}
                activeLanguage={activeLanguage}
            />
            <ToastContainer />
        </>
    );
};
