import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { ErrorScene } from './ErrorScene';
import Providers from '../contexts/Providers';
import React from 'react';
import Scenes from './Scenes';
import { strings } from '../i18n/localization';

const PageNotFound = () => {
    return <ErrorScene>{strings.errors.pageNotFound}</ErrorScene>;
};

const App = () => {
    return (
        <BrowserRouter>
            <Providers>
                <Switch>
                    <Route path="/" exact component={Scenes} />
                    <Route component={PageNotFound} />
                </Switch>
            </Providers>
        </BrowserRouter>
    );
};

export default App;
