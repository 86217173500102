import { ErrorMessage } from './generic/ErrorMessage';
import { ReactComponent as Logo } from '../assets/logo.svg';
import React from 'react';

export const ErrorScene = React.memo(({ children }) => {
    return (
        <section className="container flex flex-col flex-grow">
            <nav>
                <Logo className="h-auto py-8 icon-primary w-logo" />
            </nav>
            <article className="mt-10">
                <ErrorMessage className="max-w-lg mx-auto">
                    {children}
                </ErrorMessage>
            </article>
        </section>
    );
});
