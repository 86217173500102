import { useEffect, useState } from 'react';

import { useCategories } from './categories';
import { useRewards } from './rewards';

export const useData = () => {
    const [categories, , categoriesError] = useCategories();
    const [data, setData] = useState([]);
    const [rewards, , rewardsError] = useRewards();

    useEffect(() => {
        if (categories && rewards) {
            for (let c = 0; c < categories.length; c++) {
                const category = categories[c];
                setData(data => [
                    ...data,
                    {
                        ...category,
                        products: rewards.filter(
                            reward => reward.category_id === category.id
                        ),
                    },
                ]);
            }
        }
    }, [categories, rewards]);

    return [data, !data.length, categoriesError || rewardsError];
};
