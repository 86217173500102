import AppProvider from './App';
import LanguageProvider from './Language';
import React from 'react';

const Providers = ({ children }) => (
    <LanguageProvider>
        <AppProvider>{children}</AppProvider>
    </LanguageProvider>
);

export default Providers;
