import { ReactComponent as Cross } from '../../assets/icons/cross.svg';
import React from 'react';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#modal');

const Modal = ({
    header,
    isOpen = false,
    hideCloseButton = false,
    shouldCloseOnEsc = true,
    shouldCloseOnOverlayClick = false,
    hideHeader = false,
    handleModal,
    contentLabel,
    children,
    className = 'absolute top-0 left-0 right-0 mx-auto overflow-y-auto bg-white outline-none',
}) => {
    return (
        <ReactModal
            isOpen={isOpen}
            shouldCloseOnEsc={shouldCloseOnEsc}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            contentLabel={contentLabel}
            closeTimeoutMS={150}
            onRequestClose={() => handleModal(false)}
            className={className}>
            <>
                {!hideHeader && (
                    <header className="h-56 shadow bg-primary"></header>
                )}
                {!hideCloseButton && (
                    <button
                        className="fixed top-0 right-0 z-10 px-3 py-2 mr-4 bg-white rounded-b-lg shadow-lg"
                        onClick={() => handleModal(false)}>
                        <Cross className="icon icon-small icon-primary" />
                    </button>
                )}
                <div
                    className={`container relative max-w-xl mb-24 ${
                        !hideHeader ? '-mt-40' : ''
                    }`}>
                    {children}
                </div>
            </>
        </ReactModal>
    );
};

export default Modal;
