import { Product } from './Product';
import React from 'react';
import { isMobileOrTablet } from '../lib/detect-device';
import { strings } from '../i18n/localization';

export const Products = ({ products, initialRewards }) => {
    let wrapperClasses = 'products-wrapper';

    if (isMobileOrTablet() && products.length > 1) {
        wrapperClasses += ' mobile';
    }

    return products.length >= 1 ? (
        <div className={wrapperClasses}>
            {products.map(
                product =>
                    !!product.visible && (
                        <Product
                            key={product.id}
                            product={product}
                            count={initialRewards.find(
                                reward => reward.id === product.id
                            )}
                        />
                    )
            )}
        </div>
    ) : (
        <p className="container mb-8 text-secondary" role="alert">
            {strings.general.noProducts}
        </p>
    );
};
