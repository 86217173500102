import { ADD, SUBTRACT, DELETE } from './Product';

import { Button } from './generic/Button';
import { ReactComponent as Minus } from '../assets/icons/minus.svg';
import { ReactComponent as Plus } from '../assets/icons/plus.svg';
import React from 'react';
import { strings } from '../i18n/localization';

const ProductCounter = ({ disabled, product, productCount, handleSelect }) => {
    return (
        <div className="flex items-center justify-between w-full">
            <Button
                type="circle"
                color="secondary"
                onClick={() =>
                    handleSelect(
                        product.id,
                        product.cost,
                        productCount > 1 ? SUBTRACT : DELETE
                    )
                }>
                <Minus />
            </Button>
            <span className="text-gray">
                {strings.product.amount}:{' '}
                <span className="font-bold">{productCount}</span>
            </span>
            <Button
                disabled={disabled}
                type="circle"
                color="primary"
                onClick={() => handleSelect(product.id, product.cost, ADD)}>
                <Plus />
            </Button>
        </div>
    );
};

export default ProductCounter;
