import React from 'react';
import { strings } from '../../i18n/localization';

const Spinner = ({ color, className }) => {
    let spinnerClasses = 'spinner';

    if (className) {
        spinnerClasses += ` ${className}`;
    }

    if (color) {
        spinnerClasses += ` ${color}`;
    }

    return <div className={spinnerClasses}>{strings.general.loading}...</div>;
};

export default Spinner;
