import React, { useState } from 'react';

import { Button } from './generic/Button';
import { ReactComponent as Check } from '../assets/icons/check.svg';
import { IconText } from './generic/IconText';
import { ReactComponent as Logo } from '../assets/logo.svg';
import Modal from './generic/Modal';
import Spinner from './generic/Spinner';
import { SuccessMessage } from './generic/SuccessMessage';
import { strings } from '../i18n/localization';
import { toast } from 'react-toastify';
import useAxios from 'axios-hooks';

const TOAST_SETTINGS = {
    position: 'bottom-left',
    autoClose: 3500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
};

export const BottomBar = React.memo(({ user, data }) => {
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [
        { data: postData, loading: postLoading, error: postError },
        postForm,
    ] = useAxios(
        {
            url: '/api/submit',
            method: 'POST',
        },
        { manual: true }
    );
    const { points, rewards } = data;

    const handleConfirmationModal = () => {
        setConfirmationModalOpen(oldModal => !oldModal);
    };

    const submitSelection = () => {
        if (rewards.length > 0) {
            postForm({
                data: {
                    user: user.uuid,
                    products: rewards,
                },
            });
        } else {
            toast.warn(strings.errors.select, TOAST_SETTINGS);
        }
    };

    const modalOpen = postData && !postError && !postLoading;

    if (postError) {
        toast.error(
            `${strings.errors.submitError}: ${postError.message}.`,
            TOAST_SETTINGS
        );
    }

    let rewardsCount = 0;
    rewards.forEach(reward => {
        rewardsCount += reward.count;
    });

    return (
        <>
            <aside
                className={`fixed bottom-0 left-0 flex items-center w-full h-16 py-2 bg-white shadow-top-xl ${
                    modalOpen || confirmationModalOpen ? '' : 'z-100'
                }`}>
                <div className="container flex items-center">
                    <div className="flex flex-col flex-wrap justify-between flex-grow mr-2 sm:mr-4 md:mr-8 sm:flex-row">
                        <IconText
                            type="small-mobile"
                            className="mt-0 mb-1 text-sm sm:mb-0"
                            icon="coins">
                            {points}{' '}
                            {points === 1
                                ? strings.bar.availablePoint.toLowerCase()
                                : strings.bar.availablePoints.toLowerCase()}
                        </IconText>
                        <IconText
                            type="small-mobile"
                            className="mt-0 mb-0 text-sm"
                            icon="list">
                            {rewardsCount}{' '}
                            {rewardsCount === 1
                                ? strings.bar.selectedReward.toLowerCase()
                                : strings.bar.selectedRewards.toLowerCase()}
                        </IconText>
                    </div>
                    <Button
                        disabled={rewards.length === 0}
                        onClick={handleConfirmationModal}
                        ariaLabel={strings.bar.confirm}
                        className="text-sm sm:py-2 sm:min-w-button">
                        <div className="flex items-center my-1 dont-break-out">
                            {postLoading ? (
                                <Spinner
                                    className="mx-auto icon"
                                    color="white"
                                />
                            ) : (
                                <>
                                    <Check className="icon icon-small icon-white sm:mr-3" />
                                    <span className="hidden sm:block">
                                        {strings.bar.confirm}
                                    </span>
                                </>
                            )}
                        </div>
                    </Button>
                </div>
            </aside>
            {confirmationModalOpen && !modalOpen && (
                <Modal
                    hideCloseButton={true}
                    shouldCloseOnEsc={true}
                    isOpen={confirmationModalOpen}
                    handleModal={handleConfirmationModal}
                    className="flex items-center justify-center">
                    <section className="container flex flex-col flex-grow">
                        <article className="overflow-hidden bg-white rounded-lg shadow-xl">
                            <div className="p-4 px-6 md:p-8">
                                <h1 className="mt-0">
                                    {strings.bar.confirmTitle}
                                </h1>
                                <p>{strings.bar.confirmDescription}</p>
                            </div>
                            <div className="flex flex-col-reverse justify-center p-3 px-6 sm:flex-row sm:justify-end bg-body">
                                <Button
                                    type="transparent"
                                    onClick={handleConfirmationModal}
                                    ariaLabel={strings.general.cancel}
                                    className="mx-auto mt-2 text-sm sm:mt-0 sm:mx-2 sm:py-2">
                                    <div className="flex items-center my-1 dont-break-out">
                                        <span className="block text-center text-dark">
                                            {strings.general.cancel}
                                        </span>
                                    </div>
                                </Button>
                                <Button
                                    onClick={submitSelection}
                                    ariaLabel={strings.bar.confirm}
                                    className="mx-auto text-sm sm:mx-0 sm:py-2 sm:min-w-button">
                                    <div className="flex items-center my-1 dont-break-out">
                                        {postLoading ? (
                                            <Spinner
                                                className="mx-auto icon"
                                                color="white"
                                            />
                                        ) : (
                                            <>
                                                <Check className="mr-3 icon icon-small icon-white" />
                                                <span className="block">
                                                    {strings.bar.confirm}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </Button>
                            </div>
                        </article>
                    </section>
                </Modal>
            )}
            {modalOpen && (
                <Modal
                    hideHeader={true}
                    hideCloseButton={true}
                    shouldCloseOnEsc={false}
                    isOpen={modalOpen}>
                    <section className="container flex flex-col flex-grow">
                        <nav>
                            <Logo className="h-auto py-8 icon-primary w-logo" />
                        </nav>
                        <article className="mt-10">
                            <SuccessMessage className="max-w-lg mx-auto">
                                {strings.success.sent}
                            </SuccessMessage>
                        </article>
                    </section>
                </Modal>
            )}
        </>
    );
});
